import { createContext } from 'react'

export type OverlaySource =
  | 'complete_the_look'
  | 'flyout_cart'
  | 'more_to_love'
  | 'builder_product_tile'
  | 'desired_size'

interface OverlayProductContext {
  overlayPermalink: string
  setOverlayPermalink: (permalink: string) => void
  selectedOverlaySize: string
  setSelectedOverlaySize: (size: string) => void
  overlayPreviewedSize: string
  setOverlayPreviewedSize: (size: string) => void
  overlayError: string
  setOverlayError: (arg0: string) => void
  pdpCombinedPricesTreatment: string
  setPdpCombinedPricesTreatment: (arg0: string) => void
  isPdpCombinedPricesTreatmentLoading: boolean
  setIsPdpCombinedPricesTreatmentLoading: (arg0: boolean) => void
  showOverlay: boolean
  setShowOverlay: (boolean) => void
  overlaySource: OverlaySource
  setOverlaySource: (source: OverlaySource) => void
}

const Context = createContext<Partial<OverlayProductContext>>({})

export default Context
