import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import useAppConfig from 'lib/network/hooks/useAppConfig'
import { get } from 'lib/network/fetchers/everlane'
import ApiError from 'lib/network/ApiError'
import checkForIsBuilderEditing from 'components/core/BuilderComponent/BuilderHelpers'
import usePrevious from './usePrevious'

type BFFType = {
  enabled: boolean
  donationReached?: boolean
  goal?: number
  multiplier?: number
  currentDonation?: number
}

type SWRReturnType = { donation: string | null; error: string | null }

const REFRESH_RATE = 4200

const useBlackFriday = (): BFFType => {
  const [currentDonation, setCurrentDonation] = useState<number>(null)
  const [shouldFetch, setShouldFetch] = useState<boolean>(true)

  const { data: appConfig } = useAppConfig()
  const { blackFridayDonationMultiplier, blackFridayDonationGoal } = appConfig ?? {}

  // TODO: Once we upgrade to SWR 2.0 we can rely on the `keepPreviousData` option in `useSWR`. Instead
  // We are using this custom hook to capture fallback data in the event useSWR returns an unknown error while enabled.
  const previousDonation = usePrevious(currentDonation) || null

  const fetcher = async url => {
    const { error, donation } = await get(url)

    if (checkForIsBuilderEditing()) {
      return { donation, error: null }
    }

    return { donation, error }
  }

  const { data } = useSWR<SWRReturnType, ApiError>('/api/v2/orders/black_friday_fund', fetcher, {
    isPaused: () => !shouldFetch,
    refreshInterval: shouldFetch ? REFRESH_RATE : 0,
    onErrorRetry: (error, _, __, revalidate, { retryCount }) => {
      if (error.status || retryCount >= 3 || !shouldFetch) {
        return
      }

      setTimeout(() => revalidate({ retryCount }), REFRESH_RATE)
    },
  })
  const { donation, error } = data ?? {}

  useEffect(() => {
    setShouldFetch(!error)
  }, [error])

  useEffect(() => {
    const donationAsNumber = donation ? Number(donation) : Number(previousDonation)
    setCurrentDonation(donationAsNumber)
  }, [donation, previousDonation])

  const donationReached = useMemo(() => {
    const donationGoal = Number(blackFridayDonationGoal)
    return currentDonation ? currentDonation >= donationGoal : false
  }, [currentDonation, blackFridayDonationGoal])

  return {
    enabled: !error,
    donationReached,
    currentDonation,
    goal: blackFridayDonationGoal,
    multiplier: blackFridayDonationMultiplier,
  }
}

export default useBlackFriday
