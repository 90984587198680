import Router from 'next/router'
import Menu, { MenuCategory } from 'types/Menu'

export const isMensUrl = () => {
  const { permalink, style } = Router.query as any

  return (permalink && permalink.substring(0, 3) === 'men') || style === 'mens'
}

export const getPermalinkFromUrl = (urlOrPermalink: string) => {
  // Often links are set in Builder as a fully qualified url, rather than
  // a relative path. This causes bugs when matching against the current
  // path, so we are filtering out just the relative path here.
  try {
    const url = new URL(urlOrPermalink)
    return url.pathname + url.search + url.hash
  } catch (e) {
    // this means a regular permalink was passed in
    return urlOrPermalink
  }
}

const getMenuByName = (menus, name) => {
  return menus.find(item => item.text === name)
}

const getMenuByGender = (menus, gender) => {
  const name = gender?.toLowerCase() === 'male' ? 'Men' : 'Women'
  return getMenuByName(menus, name)
}

/* UPDATED recursiveSubMenuSearch that does not look for permalink but instead creates a flatMap of the categories, extracts those that are not desktop-only, and checks to see if the category title matches(so we can include secondary menu items like About and Everworld) */
export const recursiveSubMenuSearch = (menus, selectedSubMenuTitle) => {
  return menus
    .flatMap((menu: Menu) => menu)
    .flatMap((menu: Menu) => menu.categories)
    .filter(
      (category: MenuCategory) =>
        category.platform === 'mobile' || category.platform === 'desktop-mobile',
    )
    .filter((category: MenuCategory) => category.text === selectedSubMenuTitle)[0]
}

export const recursiveMenuSearch = (menus, permalink, gender) => {
  const genderedPath = gender === 'male' ? '/mens' : '/womens'
  const newPath = permalink === '/' ? genderedPath : permalink

  const foundMenuItem = menus.find(menu => {
    if (menu.url === newPath) return true
    if (menu.categories === undefined) return false

    const categoryPaths = menu.categories
      .filter(category => category.platform !== 'mobile')
      .map(category => getPermalinkFromUrl(category.permalink))
      .find(link => link === newPath)

    if (categoryPaths) return true

    const matchedSubcategory = menu.categories
      .filter(category => category.platform !== 'mobile')
      .filter(category => category.permalink?.length > 0)
      .flatMap(category => category.sections || {})
      .flatMap(sections => sections.links || {})
      .find(link => permalink === getPermalinkFromUrl(link.permalink))

    if (matchedSubcategory) return true

    return false
  })

  if (foundMenuItem) {
    return foundMenuItem
  }

  if (permalink.includes('women')) {
    return getMenuByName(menus, 'Women')
  }

  if (permalink.includes('men')) {
    return getMenuByName(menus, 'Men')
  }

  return getMenuByGender(menus, gender)
}
